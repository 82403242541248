import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'

const galleryLinks = {
  'Walker Art Gallery': 'http://www.atkinson-gallery.co.uk/',
  'Atkinson Gallery': 'http://www.atkinson-gallery.co.uk/',
  'View Two Gallery': 'http://www.viewtwogallery.co.uk/',
  'Bluecoat Gallery': 'http://www.bluecoatartscentre.com/',
}

const LinkedGallery = ({ gallery }) => {
  if (galleryLinks[gallery] !== undefined) {
    return (
      <a href={galleryLinks[gallery]} rel="nofollow noreferrer">
        {gallery}
      </a>
    )
  } else {
    return <span>{gallery}</span>
  }
}

const ExhibitionListings = ({ exhibitions, title }) => (
  <React.Fragment>
    <h2>{title}</h2>
    <table>
      <tbody>
        {exhibitions.map((m, i) => (
          <tr key={i}>
            <td width="35%">{m.node.name}</td>
            <td width="35%">
              <LinkedGallery gallery={m.node.gallery} />
            </td>
            <td width="20%">{m.node.location}</td>
            <td width="10%">{m.node.date}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </React.Fragment>
)

const ExhibitionsPage = ({ data }) => (
  <Layout currentPath="/exhibitions" title="Exhibitions">
    <h1>Exhibitions</h1>
    <br />
    <ExhibitionListings
      title="Solo Exhibitions"
      exhibitions={data.solo.edges}
    />
    <br />
    <ExhibitionListings
      title="Group Exhibitions"
      exhibitions={data.group.edges}
    />
  </Layout>
)

export default ExhibitionsPage

export const query = graphql`
  query {
    solo: allSoloCsv {
      edges {
        node {
          id
          gallery
          name
          location
          date
        }
      }
    }
    group: allGroupCsv {
      edges {
        node {
          id
          gallery
          name
          location
          date
        }
      }
    }
  }
`
